/* src/index.css */

@tailwind base;
@tailwind components;
@tailwind utilities;


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: Arial, sans-serif;
    background-color: #f0f0f0;
    color: #333;
}

h1, h2, h3, h4, h5, h6 {
    margin: 10px 0;
}

button {
    cursor: pointer;
}

a {
    text-decoration: none;
    color: inherit;
}
